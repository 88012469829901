import { Route, Routes } from "react-router-dom";

import IndexPage from "@/pages/index";
import DocsPage from "@/pages/docs";
import PricingPage from "@/pages/pricing";
import AboutPage from "@/pages/about";

// // add global.css
import "../app/globals.css";
import BecomePremium from "./pages/becomePremium";
import PrivacyAndPolicy from "./pages/privacyAndPolicy";
import HandlePayment from "./pages/handlePayment";
import CustomerDashboard from "./pages/customerDashboard";
import Payment from "./pages/payment";
import Terms from "./pages/terms";

function App() {
  return (
    <Routes>
      <Route element={<IndexPage />} path="/" />
      <Route element={<DocsPage />} path="/docs" />
      <Route element={<PrivacyAndPolicy />} path="/privacy-and-policy" />
      <Route element={<PricingPage />} path="/pricing" />
      <Route element={<AboutPage />} path="/about" />
      <Route element={<BecomePremium />} path="/premium" />
      <Route element={<CustomerDashboard />} path="/settings" />
      <Route element={<Payment />} path="/checkout" />
      <Route element={<Terms />} path="/terms" />
      <Route element={<HandlePayment />} path="/payment/success" />

      <Route element={<HandlePayment />} path="/payment/cancel" />
      <Route element={<div>Not found</div>} path="/*" />
    </Routes>
  );
}

export default App;
