import { useState, useEffect, useRef } from "react";

import { ChevronDown, Search, Clock, Check, BookPlus } from "lucide-react";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import useAppStore from "@/store/store";
import { Modal, ModalContent, ModalHeader } from "@nextui-org/modal";
import { ChannelProvider, TranslateModel } from "@/proivder/channelProvider";
import { NavItem } from "./bottomBar";

interface LanguageSelectorProps {
  onSelect: (languages: string[]) => void;
  selectedLanguages: string[];
  mobile?: boolean;
  disabled?: boolean;
  isAllow: boolean;
}

export default function TranslationLanguageSelector({
  onSelect,
  selectedLanguages,
  mobile,
  disabled,
}: LanguageSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [_selectedLanguages, _setSelectedLanguages] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [recentLanguages, setRecentLanguages] = useState<string[]>([]);
  const allLanguages = useAppStore((state) => state.languages);

  const isOpenRef = useRef<boolean>(false);
  const isMobile = window.innerWidth < 768;

  const channel = useAppStore((state) => state.channel);
  const userId = useAppStore((state) => state.userId);

  const [transcriptModels, setTranscriptModels] = useState<TranslateModel[]>(
    []
  );

  async function loadTranscriptModels() {
    const models = await ChannelProvider.getTranslateModels();

    setTranscriptModels(models);
  }

  useEffect(() => {
    // Load recent languages from localStorage on component mount
    const storedRecent = localStorage.getItem("recentLanguages");

    if (storedRecent) {
      setRecentLanguages(JSON.parse(storedRecent));
    }

    loadTranscriptModels();
  }, []);

  useEffect(() => {
    _setSelectedLanguages(selectedLanguages);
  }, [selectedLanguages]);

  useEffect(() => {
    if (
      !selectedLanguages.length &&
      allLanguages.length &&
      !mobile &&
      userId === channel?.owner &&
      !isOpenRef.current
      && !isMobile
    ) {
      isOpenRef.current = true;
      console.log(channel?.name);

      setIsOpen(true);
    } else if (
      !selectedLanguages.length &&
      allLanguages.length &&
      mobile &&
      userId === channel?.owner &&
      isMobile &&
      !isOpenRef.current
    ) {
      isOpenRef.current = true;

      setIsOpen(true);
    }
  }, [selectedLanguages]);

  const updateRecentLanguages = (lang: string) => {
    const updatedRecent = [
      lang,
      ...recentLanguages.filter((l) => l !== lang),
    ].slice(0, 3);

    setRecentLanguages(updatedRecent);
    localStorage.setItem("recentLanguages", JSON.stringify(updatedRecent));
  };

  const selectLanguage = (lang: string) => {
    let _langs = _selectedLanguages;

    if (_selectedLanguages.includes(lang)) {
      _langs = _selectedLanguages.filter((l) => l !== lang);

      _setSelectedLanguages(_langs);
    } else {
      _langs = [..._selectedLanguages, lang];
      _setSelectedLanguages(_langs);
    }

    updateRecentLanguages(lang);
    //setIsOpen(false);
    onSelect(_langs);
  };

  const filteredLanguages = allLanguages.filter((lang) =>
    lang.language.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {!mobile ? (
        <Button
          onClick={() => {
            setIsOpen(true);
          }}
          variant="solid"
          className="w-full h-7 justify-between"
          size="sm"
          //       className=" min-w-20 max-w-20 h-7 max-lg:hidden"
        >
          {_selectedLanguages.length > 0
            ? _selectedLanguages.length +
              (_selectedLanguages.length > 1 ? " Translates" : " Translate")
            : "Select Translate"}
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      ) : (
        <NavItem
          icon={<BookPlus className="w-5 h-5" />}
          label="Language"
          isActive={false}
          disabled={disabled}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      )}
      <Modal
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        onClose={() => {
          isOpenRef.current = false;
          window.scrollTo(0, 0);
        }}
        size="3xl"
      >
        <ModalContent className=" p-4">
          <ModalHeader className="p-0 pb-5">
            <h2 className=" text-xl font-semibold">
              Select Translate language
            </h2>
          </ModalHeader>
          <div className="relative">
            <Search className="absolute left-0 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search languages"
              className="pl-8"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {recentLanguages.length > 0 && (
            <div className="mt-4">
              <h3 className="mb-2 text-sm font-medium text-muted-foreground flex items-center">
                <Clock className="mr-2 h-4 w-4" />
                Recent
              </h3>
              <div className="grid grid-cols-3 gap-2">
                {allLanguages
                  .filter((item) => recentLanguages.includes(item.code))
                  .map((lang) => (
                    <Button
                      key={lang.code}
                      variant="flat"
                      color="secondary"
                      className="justify-start font-normal"
                      onClick={() => selectLanguage(lang.code)}
                      endContent={
                        _selectedLanguages.includes(lang.code) ? (
                          <Check className="h-4 w-4" />
                        ) : null
                      }
                    >
                      {lang.language}
                    </Button>
                  ))}
              </div>
            </div>
          )}
          <ScrollShadow className="h-[300px] mt-4">
            <div className="grid grid-cols-2 gap-2">
              {filteredLanguages
                .filter((v) => v.code != channel?.audio[0].lang)
                .map((lang) => (
                  <Button
                    key={lang.code}
                    variant="ghost"
                    className="justify-start font-normal"
                    onClick={() => selectLanguage(lang.code)}
                    endContent={
                      _selectedLanguages.includes(lang.code) ? (
                        <Check className="h-4 w-4" />
                      ) : null
                    }
                    disabled={
                      !!!transcriptModels.find(
                        (model) => model.language === lang.code && model.load
                      )
                    }
                    isDisabled={
                      !!!transcriptModels.find(
                        (model) => model.language === lang.code && model.load
                      )
                    }
                  >
                    {lang.language}
                  </Button>
                ))}
            </div>
          </ScrollShadow>
        </ModalContent>
      </Modal>
    </>
  );
}
