/* eslint-disable react/no-unescaped-entities */
import DefaultLayout from "@/layouts/default";

export default function Terms() {
  return (
    <DefaultLayout>
      <section className="flex flex-col items-center justify-center gap-4 py-8 md:py-10 privacy">
        <div className="inline-block text-justify text-lg">
          <h1 className="font-semibold text-4xl mb-4">TERMS OF SERVICE</h1>
          <p className="mb-2">Last Updated: March 27, 2025</p>
          
          <p className="mb-2">
            Welcome to EMSBT (the "App"), a Facebook-integrated application designed to enhance social interactions by providing customizable content sharing tools. By accessing or using the App, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree, do not use the App.
          </p>

          <h2 className="mb-2 text-2xl">1. Acceptance of Terms</h2>
          <p className="mb-2">By using the App through Facebook, you confirm that you:</p>
          <ul>
            <li>
              <p>Are at least 13 years old (or the minimum age required in your jurisdiction to use Facebook).</p>
            </li>
            <li>
              <p>Have read, understood, and agree to these Terms, Facebook's Terms of Service (https://www.facebook.com/terms), and our Privacy Policy.</p>
            </li>
            <li>
              <p>Acknowledge that the App may collect, use, and share data as described in our Privacy Policy and in compliance with Facebook's Data Policy.</p>
            </li>
          </ul>

          <h2 className="mb-2 text-2xl">2. User Obligations</h2>
          <p className="mb-2">You agree to:</p>
          <ul>
            <li>
              <p>Use the App only for lawful purposes and in compliance with all applicable laws.</p>
            </li>
            <li>
              <p>Not engage in abusive behavior, spam, harassment, or unauthorized access to other users' accounts or data.</p>
            </li>
            <li>
              <p>Not reverse-engineer, decompile, or exploit vulnerabilities in the App or Facebook's platform.</p>
            </li>
            <li>
              <p>Comply with Facebook's Community Standards and policies at all times.</p>
            </li>
          </ul>

          <h2 className="mb-2 text-2xl">3. Privacy & Data Use</h2>
          <ul>
            <li>
              <p>The App may access certain Facebook user data (e.g., public profile, friends list, email) as permitted by Facebook and with your consent.</p>
            </li>
            <li>
              <p>We will handle your data in accordance with our Privacy Policy and Facebook's Data Policy.</p>
            </li>
            <li>
              <p>We do not sell your data to third parties but may share anonymized analytics for service improvement.</p>
            </li>
          </ul>

          <h2 className="mb-2 text-2xl">4. Intellectual Property</h2>
          <ul>
            <li>
              <p>All content, trademarks, and code in the App are owned by EMS Broadcast Technology LLC or licensed for use.</p>
            </li>
            <li>
              <p>You retain ownership of content you create or share via the App but grant us a non-exclusive license to display, modify, and distribute it for app functionality.</p>
            </li>
            <li>
              <p>Do not infringe copyrights, trademarks, or other rights when using the App.</p>
            </li>
          </ul>

          <h2 className="mb-2 text-2xl">5. Disclaimers</h2>
          <ul>
            <li>
              <p>The App is provided "as is" without warranties of any kind. We do not guarantee uninterrupted, error-free, or secure operation.</p>
            </li>
            <li>
              <p>We are not responsible for content posted by users or third parties via the App.</p>
            </li>
            <li>
              <p>Facebook is not liable for the App's functionality or content.</p>
            </li>
          </ul>

          <h2 className="mb-2 text-2xl">6. Limitation of Liability</h2>
          <p className="mb-2">
            To the extent permitted by law, EMS Broadcast Technology LLC and its affiliates shall not be liable for indirect, incidental, or consequential damages arising from your use of the App.
          </p>

          <h2 className="mb-2 text-2xl">7. Termination</h2>
          <ul>
            <li>
              <p>We reserve the right to suspend or terminate your access to the App at any time for violations of these Terms.</p>
            </li>
            <li>
              <p>You may stop using the App at any time by revoking its permissions via Facebook.</p>
            </li>
          </ul>

          <h2 className="mb-2 text-2xl">8. Amendments</h2>
          <p className="mb-2">
            We may update these Terms periodically. Continued use of the App after changes constitutes acceptance. We will notify users of material changes via email or in-app alerts.
          </p>

          <h2 className="mb-2 text-2xl">9. Contact Us</h2>
          <p className="mb-2">For questions about these Terms, contact us at:</p>
          <ul>
            <li>
              <p>EMS Broadcast Technology LLC</p>
            </li>
            <li>
              <p>Email: info@emsbt.ai</p>
            </li>
           
            <li>
              <p>
                Website:{" "}
                <a
                  href="http://emsbt.ai"
                  rel="external nofollow noopener noreferrer"
                  target="_blank"
                >
                  https://emsbt.ai
                </a>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </DefaultLayout>
  );
}